import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import Header from "./header"
import Footer from "./footer"
import { graphql } from "gatsby"
import MDXRenderer from "gatsby-mdx/mdx-renderer"

const GuideLayout = ({ data: { mdx, location } }) => {
  // console.log(location)
  return (
    <>
      <Container fluid className="px-0 main">
        <Header location={location} />
        <Container className="mt-4">
          <Row>
            <Col>
              <MDXRenderer>{mdx.code.body}</MDXRenderer>
            </Col>
          </Row>
        </Container>
      </Container>
      <Footer />
    </>
  )
}

export const pageQuery = graphql`
  query GuidePostQuery($id: String) {
    mdx(id: { eq: $id }) {
      id
      exports {
        metadata {
          title
          path
          date
        }
      }
      code {
        body
      }
    }
  }
`

export default GuideLayout
